.ReactModal__Overlay {
  background-color: hsla(0, 0%, 0%, 0.5) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 99;
}

.ReactModal__Content {
  opacity: 1;
  position: initial !important;
  height: 670px;
  width: 640px;
  background: white !important;
}

.placeholder-top::placeholder {
  vertical-align: top;
}
