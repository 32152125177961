::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body::-webkit-scrollbar {
  width: 0.8em;
  height: 0.6rem;
}

.MuiTableContainer-root::-webkit-scrollbar {
  height: 0.8rem;
}

body,
.MuiTableContainer-root {
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 50px !important;
  }
}

body,
.MuiTableContainer-root {
  &::-webkit-scrollbar-thumb {
    background-color: #aaa7a7;
    border-radius: 50px !important;
  }
}

@media print {
  .noprint {
    display: none;
  }
}
