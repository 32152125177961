.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container{
    z-index: -1;
}

.react-datepicker__year-text--selected, .react-datepicker__year-text--selected:hover, .react-datepicker__day--selected, .react-datepicker__day--selected:hover{
    background-color: #CF001C;
    border-color: #CF001C;
    outline: none;
}

.react-datepicker__year-text--keyboard-selected, .react-datepicker__day--keyboard-selected{
    background-color: #f9cace;
    outline: none;
}

.react-datepicker__year-text--keyboard-selected:hover, .react-datepicker__day--keyboard-selected:hover{
    background-color: #CF001C;
    color: white;
}

