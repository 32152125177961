

.booking-calender  .react-calendar__navigation {
    display: none;
}

.booking-calender  .react-calendar__month-view__days__day--neighboringMonth {
    color: #AAA;
    pointer-events: none;
}

.booking-calender  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    color: #AAA;
}

.booking-calender  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

button.react-calendar__month-view__days__day:disabled{
    color: #aaa;
}

.booking-calender  .react-calendar__tile--active {
    background-color: #CF001C;
    color: white;
    border-radius: 100%;
    width: 2px !important;
}

.booking-calender button.react-calendar__tile {
    padding: 12px 0px 10px 0px;
}

.booking-calender .react-calendar__month-view__weekdays{
    background-color: #EEEEEE !important;
}

.booking-calender .react-calendar__month-view__weekdays__weekday{
    padding: 8px 0;
    color: black !important;
    border: 1px solid #DDDDDD;
    font-size: 13px;
    font-weight: 500;
}

.booking-calender .react-calendar__month-view__days button{
    border: 1px solid #DDDDDD;
    padding: 8px 3px;
}

.booking-calender .booking-data{
    color: #941A25;
    font-weight: 500;
    background-color: #FDF2F3;
    position: relative;
    overflow: unset !important;
}

.booking-calender .booking-data .booking-content{
    display: none;
}

.booking-content::-webkit-scrollbar {
    width: 8px;
}

.booking-content::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 4px;
}

.booking-content::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
}

.booking-calender .booking-data:hover .booking-content{
    position: absolute;
    display: block;
    top: 43px;
    background-color: #F1F7FE;
    box-shadow: 0px 1px 2px #22222233;
    border: 1px solid #DDDDDD;
    font-size: 13px;
    text-align: left;
    width: 180px;
    padding: 8px;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 10;
}