.HeaderNavTab {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  color: #222;
}

#root
  > div
  > div:nth-child(1)
  > div
  > div
  > div.mx-auto
  > header
  > div
  > div:nth-child(9)
  > button {
  /* width: 82px; */
  /* padding: 8px 0 8px 0; */
  border-top-right-radius: 8px;
  border-end-end-radius: 8px;
}

.HeaderNavSubMenu {
  position: absolute;
  background-color: white;
  display: none;
  border: 1px solid #cccccc;
  border-radius: 0px 0px 0px 0px;
  z-index: 30;
  width: 100%;
  white-space: nowrap;
}

.HeaderNavTab:hover .HeaderNavSubMenu {
  display: block;
}

.HeaderNavTab:hover > button {
  background-color: #cf001c;
  color: white;
}
.HeaderNavTab:hover > button:last-child {
  border-top-right-radius: 8px;
  border-end-end-radius: 8px;
}

.SubMenu {
  display: none;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 0px 0px 0px 0px;
  z-index: 2;
  min-width: 180px;
}

.SubMenuTab {
  position: relative;
}

.SubMenuTab:hover > .SubMenu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
}

.link {
  text-wrap: wrap;
  border-bottom: 1px solid #cccccc;
  padding: 7px;
  font-weight: 600;
  font-size: 12px;
}

.link:hover {
  background-color: #cf001c;
  color: white;
}
/* .SubMenuTab:last-child .link{
    border-bottom: none;
    border-radius: 0px 0px 8px 8px;
} */

/* .HeaderNavTab:nth-child(9) .HeaderNavSubMenu {
    display: block;
} */
