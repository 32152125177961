/* External CSS (forgotpass.css) */
.hover-arrow .arrow-icon {
  position: absolute;
  left: -1rem;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s, transform 0.3s;
}

.hover-arrow:hover .arrow-icon {
  opacity: 1;
  transform: translateX(0);
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s;
}

input::placeholder {
  font-style: italic;
  color: #999;
}

input[type="text"]:not(:placeholder-shown),
input[type="password"]:not(:placeholder-shown) {
  font-style: normal;
  color: #333;
}

.gradientClass {
  background: transparent linear-gradient(147deg, #CF001C 0%, #941A25 100%) 0% 0% no-repeat padding-box;
  opacity: 0.7;
}

.bgClasGrad {
  background: linear-gradient(35deg, #FFF0F0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}