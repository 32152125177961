.table-component .active, .table-component .open{
    color: #03C708;
}

.table-component .on-hold{
    color: #FF7F00;
}

.table-component .inactive, .table-component .closed{
    color: red;
}