.react-calendar__navigation {
    display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #AAA;
    pointer-events: none;
}

.react-calendar__month-view__weekdays__weekday {
    text-align: center;
    color: #AAA;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

button.react-calendar__month-view__days__day:disabled{
    color: #aaa;
}

.react-calendar__tile--active {
    background-color: #CF001C;
    color: white;
    border-radius: 100%;
    width: 2px !important;
}

button.react-calendar__tile {
    padding: 12px 0px 10px 0px;
}
