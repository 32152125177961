@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img, video {
    max-width: initial;
    height: initial;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F4F9;
  min-height: 100vh;
  color: #222;
}

* {
  font-family: 'Open Sans', sans-serif;
}

