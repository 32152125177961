.react-multi-select {
    outline: initial;
    box-shadow: none;
    /* margin-left: 10px; */
}

.react-multi-select__control {
    border-radius: 6px;
    /* border: 1px solid #AAA; */
    background: #F9FAFC !important;
    outline: initial;
    box-shadow: none;
}

.react-multi-select__indicator-separator {
    display: none;
}

.react-multi-select__dropdown-indicator svg {
    width: 20px;
    height: 20px;
    fill: black;
}

.react-multi-select__control--is-focused.react-multi-select__control--menu-is-open svg {
    transform: rotate(180deg);
}

.react-multi-select__value-container{
    padding-right: 0 !important;
}

.react-multi-select__clear-indicator{
    padding: 8px 0 !important;
}

.react-multi-select__multi-value__label{
    font-size: 13px !important;
}
/* .react-multi-select__menu {

} */