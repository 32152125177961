.act.active svg {
  fill: #CF001C !important;
  font-weight: bold; /* Add this line to make the icon bold */
}

.act.active {
  color: #CF001C;
  font-weight: bold; /* Add this line to make the text bold */
}

.act.active span {
  border-left: 3px solid #CF001C;
}
