.vc-description {
  overflow-x: scroll;
  text-wrap: wrap;
  word-break: break-all;
}

.vc-description img {
  max-width: 100%;
}

.rdw-editor-main {
  height: 100px;
  width: 571px;
  padding: 0 15px 5px;
}

.DraftEditor-root {
  height: 80% !important;
}

/* notice & circular page css start */
.noticeCircular .rdw-editor-main {
  height: 250px;
  width: 100%;
  padding: 0 15px 5px;
  width: 724px;
}

/* desk/complaint-dashboard css start */
.complaintDashboardTable::-webkit-scrollbar {
  background: white;
  border-radius: 0px;
  height: 10px;
}
.complaintDashboardTable::-webkit-scrollbar-track {
  background: rgb(240, 240, 240);
  border-radius: 0px;
}
.complaintDashboardTable::-webkit-scrollbar-thumb {
  background: rgb(198, 198, 198);
  border-radius: 50px;
}
